<template>
  <b-modal
    id="leave-page-assignment-modal"
    :title="$t('ENTITLEMENT.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column p-5">
      <CompanyLeaveDetails
        class="mb-2"
        :leave="assignedLeave.leave"
        :is-busy="isBusy"
      ></CompanyLeaveDetails>

      <IndividualEmployeeLeaveAssignmentForm
        @onSubmitClicked="submitForm"
      ></IndividualEmployeeLeaveAssignmentForm>
    </div>
  </b-modal>
</template>

<script>
import IndividualEmployeeLeaveAssignmentForm from "@/modules/school/components/management/leave/assignment/IndividualEmployeeLeaveAssignmentForm";
import CompanyLeaveDetails from "@/modules/company/components/shared/CompanyLeaveDetails";

export default {
  name: "TeacherIndividualLeaveAssignmentModal",
  components: { CompanyLeaveDetails, IndividualEmployeeLeaveAssignmentForm },
  props: {
    assignedLeave: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    submitForm(form) {
      console.log("Form : ", form);

      this.$store
        .dispatch("submitTeacherIndividualAssignmentForm", {
          form: {
            id: this.assignedLeave.leave.school_leave.id,
            employee_id: this.assignedLeave.employee_id,
            test: "Ayam",
            from_date: form.from_date,
            to_date: form.to_date,
            remark: form.remark,
          },
        })
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk(
            "Successfully set the individual assignment for teacher."
          );
          this.$emit("formSubmitted", form);
        });
    },
  },
};
</script>

<style scoped></style>
